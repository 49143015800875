import classNames from 'classnames';
import React from 'react';
import styles from './ProductWidget.module.scss';
import { useNavigate } from 'react-router-dom';
import loadable from '@loadable/component';
import { VIEW_OPTIONS_TILES } from '../SearchResultsPage/SRP.definitions';
import getCDNImage from '../../utils/getCDNImage';
import { FormattedMessage } from 'react-intl';
const Product = loadable(() => import('../Product/Product'));

const ProductWidget = ({ products = [], color = 'orange', widgetTitle, productCardOriginType }) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(styles.root, {
        [styles.orange]: color === 'orange',
        [styles.green]: color === 'green',
        [styles.blue]: color === 'blue',
      })}
    >
      <div className={styles.title}>
        <FormattedMessage default="We recommend you" id="home.popular.recommend" />
      </div>
      <div className={styles.subTitle}>{widgetTitle}</div>
      <div className={styles.productsContainer}>
        {products.map((product, index) => (
          <Product
            adult={product?.adult}
            key={`pssitem${product.id}`}
            brand={product?.brand}
            classList={{ root: styles.productCard, price: styles.productPrice }}
            currency={product?.currency || process.env.REACT_APP_WEBSITE_CURRENCY}
            shortDesc={product?.shortDesc}
            EAN={product?.offers?.[0]?.EAN}
            price={product.price}
            originalPrice={product.price_original || product.price}
            id={product.id}
            image={getCDNImage(product?.thumbs?.[0])}
            isSelected={false}
            name={product.name}
            onClick={() => navigate(product.pdp_path)}
            shop={{ name: product.campaign }}
            SKU={product.SKU}
            showProductLink={false}
            url={product.pdp_path}
            view={VIEW_OPTIONS_TILES}
            status={product.new}
            shopId={product?.shop_id}
            merchantPid={product?.merchant_product_id}
            hideDescription
            isShortName={false}
            resultRank={index}
            category={product?.category}
            productCardOriginType={productCardOriginType}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductWidget;
